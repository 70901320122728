.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #17a2b8;
  min-height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(6px + 2vmin);
  color: white;
  width: auto;
  align-content: center;
}
.App-pos{
  position: relative;
  top: 150px;
}

.App-link {
  color: #61dafb;
}
.App-menu{
  display: block;
}

body{
  background-color: white;
 background-image: url("./components/img/network-main.png");
 background-size:     cover;                      /* <------ */
    background-repeat:   repeat;
  background-position: center center;              /* optional, center the image */



}
.test{
  height: 100vh;
}
.top-5{
  position: relative;
  top: 10px;
}
.testcol{
  background-color: white;
  /*  height: 100vh;*/
}
.sidebar .sidebar-menus{
  flex: 1 1 auto;
}

.dash{
  height: 100vh;
  align-items: stretch;
}

.sidebar{
    display: 'flex';
    align-content: flex-start;
    flex-flow: column;
    flex-grow: 1;
     height: '900px';
     overflow-y: 'scroll initial';
}

.menu-panel{
 display: flex;
 height: 100%;
 overflow: scroll initial;
 backgroundColor:#333;
}

.sform{
  display: flex;
  flex-flow: 1;
  overflow-y:scroll;
  max-height: 40vh;
  flex-direction: column;
}
.modal-40w {
  min-width:40%;
}

.modal-70w {
  min-width:70%;
}
.modal-80w {
  min-width:80%;
}

.modal-90w {
  min-width:90%;
}

.dropzone{
  display: flex;
  flex-flow: 1;
  overflow-y:scroll;
  max-height: 100vh;
  flex-direction: column;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
